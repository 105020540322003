import axios from '@/plugins/axios'

const ads = {
  state: {
    ads: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: '',
        per_page: 10,
        to: 1,
        total: 1,
      },
    },
  },
  mutations: {
    setAds(state, data) {
      state.ads = data
    },
  },
  getters: {
    ads: (state) => state.ads,
  },
  actions: {
    fetchAds({ commit }, options = {}) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/' + (options.type === 'text' ? 'texts' : 'images'), {
            params: options,
          })
          .then((res) => {
            commit('setAds', res.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteAds({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(
            '/admin/' + (data.type === 'text' ? 'texts/' : 'images/') + data.id,
          )
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateAds({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post(
            '/admin/' +
              (data.type === 'text' ? 'texts/update' : 'images/update'),
            data.data,
          )
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default ads
