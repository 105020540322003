import axios from 'axios'
import router from '@/router'
import store from '@/store'

const apiRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
  headers: {
    Accept: 'application/json',
  },
})
apiRequest.interceptors.request.use((config) => {
  const authToken = localStorage.getItem('tokenAdmenuAdmin')
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`
  }
  return config
})

apiRequest.interceptors.response.use(
  (response) => {
    store.commit('setLoader', false)
    return response
  },
  (error) => {
    store.commit('setLoader', false)
    const errorMessage =
      error.response?.data?.message || error.response?.data?.error
    if (errorMessage) {
      store.commit('addAlerts', {
        type: 'warning',
        text: errorMessage,
      })
    }
    if (error.response?.status === 401) {
      store.commit('removeUser')
      localStorage.removeItem('tokenAdmenuAdmin')
      if (router.currentRoute.value.path !== '/auth/login') {
        router.push('/auth/login')
      }
    }
    return Promise.reject(error)
  },
)

export default apiRequest
