<template>
  <CSidebar
    color-scheme="light"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand class="d-none d-md-flex">
      <img
        class="sidebar-brand-full ms-3"
        src="@/assets/images/logo-white.png"
        alt="Logo"
        height="35"
      />
      <img
        class="sidebar-brand-narrow"
        src="@/assets/images/small-logo.png"
        alt="Logo"
        height="35"
      />
      <CSidebarToggler @click="$store.commit('toggleUnfoldable')" />
    </CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
