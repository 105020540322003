import axios from '@/plugins/axios'

const errors = {
  state: {
    errors: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: '',
        per_page: 10,
        to: 1,
        total: 1,
      },
    },
  },
  mutations: {
    setErrors(state, data) {
      state.errors = data
    },
  },
  getters: {
    errors: (state) => state.errors,
  },
  actions: {
    fetchErrors({ commit }, options = {}) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/errors', { params: options })
          .then((res) => {
            commit('setErrors', res.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteErrors({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/admin/errors/' + data.id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateErrors({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/errors', data.data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default errors
