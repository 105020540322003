<template>
  <!--<CButton color="light" variant="outline" @click="logoutAction">
    <CIcon icon="cil-account-logout" size="xl" />
  </CButton>-->
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar
        :src="defaultBuisnessLogo"
        size="md"
        class="bg-light p-1"
        status="success"
      />
      <!--<CAvatar :src="avatar" size="md" status="success" />-->
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
        Account
      </CDropdownHeader>
      <!--<CDropdownItem>
        <CIcon icon="cil-bell" /> Updates
        <CBadge color="info-gradient" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> Comments
        <CBadge color="warning-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader
        component="h6"
        class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10 py-2"
      >
        Settings
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem> -->
      <CDropdownItem role="button" @click="logoutAction">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import defaultBuisnessLogo from '@/assets/images/small-logo.png'
import { mapActions } from 'vuex'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
      defaultBuisnessLogo,
    }
  },
  computed: {
    buisnessLogo() {
      if (this.$store.getters.getUser) {
        const logoPath = this.$store.getters.getUser.logo
        if (logoPath) {
          return `${process.env.VUE_APP_MEDIA_BASE_URL}${logoPath}`
        }
      }

      return null
    },
  },
  methods: {
    ...mapActions(['logout']),
    logoutAction() {
      this.logout().then(() => {
        this.$router.push('/auth/login')
      })
    },
  },
}
</script>
