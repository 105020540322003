import axios from '@/plugins/axios'

const settings = {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    updatePassword({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/admin/password', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default settings
