import axios from '@/plugins/axios'

const users = {
  state: {
    users: [],
    usersPage: 0,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
    setUsersPage(state, page) {
      state.usersPage = page
    },
  },
  getters: {
    getUsers: (state) => state.users,
    getUsersPage: (state) => state.usersPage,
  },
  actions: {
    fetchUsers({ commit }, options = {}) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/users', { params: options })
          .then((res) => {
            commit('setUsers', res.data.data)
            commit('setUsersPage', res.data.meta.last_page)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUser({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/users/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createUser({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/users', data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateUser({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .put('/admin/users/' + data.id, data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteUser({ commit }, id) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .delete('/admin/users/' + id)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    templeteUser() {
      return new Promise((resolve, reject) => {
        axios({
          url: '/admin/users/template',
          method: 'GET',
          responseType: 'blob',
        })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    importUsers({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/users/import', data)
          .then((res) => {
            res.data.message &&
              commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default users
