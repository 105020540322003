import axios from '@/plugins/axios'

const auth = {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    login({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/auth/login', data)
          .then((res) => {
            console.log(res)
            localStorage.setItem('tokenAdmenuAdmin', res.data.access_token)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + res.data.access_token
            resolve()
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },
    logout() {
      return new Promise((resolve) => {
        axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('tokenAdmenuAdmin')
        resolve()
      })
    },
    forgotPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/password/email', data)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    resetPassword({ commit }, data) {
      commit('setLoader', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/password/reset', data)
          .then((res) => {
            commit('addAlerts', { type: 'primary', text: res.data.message })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}

export default auth
