<template>
  <div class="alerts-wrap">
    <CAlert
      v-for="item in list"
      :key="item.id"
      class="spiner-wrap"
      :color="item.type"
      closeButton
      @update:show="() => remove(item.id)"
    >
      {{ item.text }}
    </CAlert>
  </div>
</template>
this.$store.commit('addAlerts', { type: 'primary', text: 'It`s messages'})

<script>
export default {
  name: 'AppAlerts',
  data() {
    return {
      interval: null,
    }
  },
  computed: {
    list() {
      if (this.$store.getters.getAlerts.length) {
        this.startDelete()
      } else {
        this.stopDelete()
      }
      return this.$store.getters.getAlerts
    },
  },
  methods: {
    remove(id) {
      this.$store.commit('removeAlert', id)
    },
    startDelete() {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.$store.commit('removeAlert')
        }, 2000)
      }
    },
    stopDelete() {
      clearInterval(this.interval)
      this.interval = null
    },
  },
}
</script>

<style scoped>
.alerts-wrap {
  position: fixed;
  top: 30px;
  left: 30px;
  max-height: 185px;
  min-width: 300px;
  overflow: hidden;
  z-index: 1051;
}
</style>
