import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'

import DefaultLayout from '@/layouts/DefaultLayout'
// Middleware
import auth from './middleware/auth'
import guest from './middleware/guest'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'users/:type',
        name: 'User',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/admin/User.vue'),
      },
      {
        path: 'users/create',
        name: 'Create User',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/admin/NewUser.vue'),
      },
      {
        path: 'user/:id',
        name: 'Edit User',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/admin/NewUser.vue'),
      },
      {
        path: 'ads/:type',
        name: 'Ads',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/admin/Ads.vue'),
      },
      {
        path: 'errors/:type',
        name: 'Errors',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/admin/Errors.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: {
          middleware: [auth],
        },
        component: () => import('@/views/admin/Settings.vue'),
      },
    ],
  },
  {
    path: '/',
    //redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword'),
      },
      {
        path: '/new-password',
        name: 'NewPassword',
        component: () => import('@/views/auth/NewPassword'),
      },
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/admin/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/admin/Page500'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: () => import('@/views/admin/Page404'),
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    name: 'Auth',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          middleware: [guest],
        },
        component: () => import('@/views/auth/Login'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})
router.beforeEach((to, from, next) => {
  document.title = 'AdMenu Admin | ' + to.name

  if (!to.meta.middleware) {
    return next()
  }

  // Process the middleware
  const middleware = to.meta.middleware

  const context = { to, from, next, store: useStore() }

  // If the middleware is an array, you should iterate over it
  // and apply each middleware function in sequence.
  middleware.forEach((mw) => {
    if (typeof mw === 'function') {
      mw(context)
    }
  })
})
export default router
