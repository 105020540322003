import axios from '@/plugins/axios'

const setting = {
  state: {
    dashboard: {
      ad_images_count: 0,
      ad_images_old_count: 0,
      ad_texts_count: 0,
      ad_texts_old_count: 0,
    },
    dashboard_chart: {
      ad_images: [],
      ad_texts: [],
    },
    price: {
      ad_images_old_sum: 0,
      ad_images_sum: 0,
      ad_texts_old_sum: 0,
      ad_texts_sum: 0,
    },
    price_chart: {
      ad_images: [],
      ad_texts: [],
    },
    facebook_chart: [],
    facebook_table: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: '',
        per_page: 10,
        to: null,
        total: null,
      },
    },
    google_chart: [],
    budget_chart: [],
    highest_budget_chart: [],
    campaign_per_user_chart: [],
    google_budget_chart: [],
  },
  mutations: {
    setDashboard(state, data) {
      state.dashboard = data
    },
    setDashboardChart(state, data) {
      state.dashboard_chart = data
    },
    setPrice(state, data) {
      state.price = data
    },
    setPriceChart(state, data) {
      state.price_chart = data
    },
    setFacebookChart(state, data) {
      state.facebook_chart = data.data
    },
    setFacebookTable(state, data) {
      state.facebook_table = data
    },
    setGoogleChart(state, data) {
      state.google_chart = data.data
    },
    setBudgetChart(state, data) {
      state.budget_chart = data.data
    },
    setHighestBudgetChart(state, data) {
      state.highest_budget_chart = data.data
    },
    setCampaignPerUserChart(state, data) {
      state.campaign_per_user_chart = data.data
    },
    setGoogleBudget(state, data) {
      state.google_budget_chart = data.data
    },
  },
  getters: {
    dashboard: (state) => state.dashboard,
    dashboard_chart: (state) => state.dashboard_chart,
    price: (state) => state.price,
    price_chart: (state) => state.price_chart,
    facebook_chart: (state) => state.facebook_chart,
    budget_chart: (state) => state.budget_chart,
    highest_budget_chart: (state) => state.highest_budget_chart,
    campaign_per_user_chart: (state) => state.campaign_per_user_chart,
    facebook_table: (state) => state.facebook_table,
    google_chart: (state) => state.google_chart,
    google_budget_chart: (state) => state.google_budget_chart,
  },
  actions: {
    fetchDashboard({ commit }, date) {
      commit('setLoader', true)
      Promise.all(
        [
          '/contributor/dashboard',
          '/contributor/dashboard/chart',
          '/contributor/dashboard/prices',
          '/contributor/dashboard/chart-prices',
        ].map((item, index) => {
          return new Promise((resolve, reject) => {
            let typeDate
            switch (index) {
              case 0:
                typeDate = date.dashboard
                break
              case 1:
                typeDate = date.dashboard
                break
              case 2:
                typeDate = date.price
                break
              case 3:
                typeDate = date.price
                break
            }
            axios
              .get(`${item}?start=${typeDate.start}&end=${typeDate.end}`)
              .then((res) => {
                let type
                switch (index) {
                  case 0:
                    type = 'setDashboard'
                    break
                  case 1:
                    type = 'setDashboardChart'
                    break
                  case 2:
                    type = 'setPrice'
                    break
                  case 3:
                    type = 'setPriceChart'
                    break
                }
                commit(type, res.data.data)
                resolve(res)
              })
              .catch((error) => {
                reject(error)
              })
          })
        }),
      )
    },
    /*fetchDashboardUserFacebook({ commit }, { userId, date, page }) {
      commit('setLoader', true)
      Promise.all(
        [
          `/admin/dashboard?user_id=${userId}&type=reach&dates[]=${
            date.start.split('-')[0]
          }-${date.start.split('-')[1]}&dates[]=${date.end.split('-')[0]}-${
            date.end.split('-')[1]
          }`,
          `/admin/dashboard?user_id=${userId}&type=impressions&dates[]=${
            date.start.split('-')[0]
          }-${date.start.split('-')[1]}&dates[]=${date.end.split('-')[0]}-${
            date.end.split('-')[1]
          }`,
          `/admin/dashboard?user_id=${userId}&type=clicks&dates[]=${
            date.start.split('-')[0]
          }-${date.start.split('-')[1]}&dates[]=${date.end.split('-')[0]}-${
            date.end.split('-')[1]
          }`,
          `/admin/dashboard?user_id=${userId}&type=ctr&dates[]=${
            date.start.split('-')[0]
          }-${date.start.split('-')[1]}&dates[]=${date.end.split('-')[0]}-${
            date.end.split('-')[1]
          }`,
          `/admin/dashboard?user_id=${userId}&type=spend&dates[]=${
            date.start.split('-')[0]
          }-${date.start.split('-')[1]}&dates[]=${date.end.split('-')[0]}-${
            date.end.split('-')[1]
          }`,
          `/admin/user/budget?user_id=${userId}&dates[]=${
            date.start.split('-')[0]
          }-${date.start.split('-')[1]}&dates[]=${date.end.split('-')[0]}-${
            date.end.split('-')[1]
          }`,
          `/admin/dashboard/table?user_id=${userId}&dates[]=${
            date.start.split('-')[0]
          }-${date.start.split('-')[1]}&dates[]=${date.end.split('-')[0]}-${
            date.end.split('-')[1]
          }`,
        ].map((item, index) => {
          return new Promise((resolve, reject) => {
            axios
              .get(`${item}`, { params: { page: page } })
              .then((res) => {
                let type
                switch (index) {
                  case 0:
                    type = 'setFacebookChart'
                    break
                  case 1:
                    type = 'setFacebookImpressionChart'
                    break
                  case 2:
                    type = 'setFacebookClickChart'
                    break
                  case 3:
                    type = 'setFacebookCtrChart'
                    break
                  case 4:
                    type = 'setFacebookSpendChart'
                    break
                  case 5:
                    type = 'setBudgetChart'
                    break

                  case 6:
                    type = 'setFacebookTable'
                    break
                }
                commit(type, res.data)
                resolve(res)
              })
              .catch((error) => {
                reject(error)
              })
          })
        }),
      )
    }*/
    fetchDashboardUserFacebook({ commit }, data) {
      commit('setLoader', true)

      // Construct the parameters
      // List of endpoints you want to fetch from
      const endpoints = [
        '/admin/dashboard',
        '/admin/user/budget',
        '/admin/user/highestbudget',
        '/admin/campaignperuserfacebook',
        // Add more endpoints if needed
      ]

      // Map each endpoint to a promise of an axios request
      const requests = endpoints.map((endpoint) => {
        return axios.get(endpoint, { params: data })
      })

      // Use Promise.all to wait for all requests to resolve
      Promise.all(requests)
        .then((responses) => {
          // responses is an array of axios responses
          responses.forEach((response, index) => {
            // You can use the index to know which endpoint this response came from
            // Commit the data from each response to the appropriate mutation
            let type
            switch (index) {
              case 0:
                type = 'setFacebookChart' // Change the mutation name as per your Vuex store
                break
              case 1:
                type = 'setBudgetChart' // Change the mutation name as per your Vuex store
                break
              case 2:
                type = 'setHighestBudgetChart' // Change the mutation name as per your Vuex store
                break
              case 3:
                type = 'setCampaignPerUserChart' // Change the mutation name as per your Vuex store
                break
              // Add more cases as needed
            }

            commit(type, response.data)
          })
          commit('setLoader', false)
        })
        .catch((errors) => {
          // Handle errors here. errors is an array of any thrown errors.
          console.error('Error fetching data:', errors)
          commit('setLoader', false)
        })
    },
    fetchDashboardUserGoogle({ commit }, data) {
      commit('setLoader', true)

      // Construct the parameters
      // List of endpoints you want to fetch from
      const endpoints = [
        '/admin/user-ads/google/statistic',
        '/admin/user/budget',
        '/admin/user/highestbudget',
        '/admin/campaignperusergoogle',
        // Add more endpoints if needed
      ]

      // Map each endpoint to a promise of an axios request
      const requests = endpoints.map((endpoint) => {
        return axios.get(endpoint, { params: data })
      })

      // Use Promise.all to wait for all requests to resolve
      Promise.all(requests)
        .then((responses) => {
          // responses is an array of axios responses
          responses.forEach((response, index) => {
            // You can use the index to know which endpoint this response came from
            // Commit the data from each response to the appropriate mutation
            let type
            switch (index) {
              case 0:
                type = 'setGoogleChart' // Change the mutation name as per your Vuex store
                break
              case 1:
                type = 'setBudgetChart' // Change the mutation name as per your Vuex store
                break
              case 2:
                type = 'setHighestBudgetChart' // Change the mutation name as per your Vuex store
                break
              case 3:
                type = 'setCampaignPerUserChart' // Change the mutation name as per your Vuex store
                break
              // Add more cases as needed
            }

            commit(type, response.data)
          })
          commit('setLoader', false)
        })
        .catch((errors) => {
          // Handle errors here. errors is an array of any thrown errors.
          console.error('Error fetching data:', errors)
          commit('setLoader', false)
        })
    },
  },
}

export default setting
