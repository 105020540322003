<template>
  <CHeader position="sticky" class="bg-primary mb-4">
    <CContainer fluid>
      <CHeaderToggler
        :class="[
          'px-md-0',
          'me-md-3',
          'd-lg-none',
          { 'prevent-hide': !sidebarVisible },
        ]"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-md-none text-white" to="/">
        <img
          class="sidebar-brand-full ms-3"
          src="@/assets/images/logo-white.png"
          alt="Logo"
          height="35"
        />
      </CHeaderBrand>
      <!--<CForm class="d-none d-md-flex">
        <CInputGroup>
          <CInputGroupText id="search-addon" class="bg-light border-0 px-1">
            <CIcon
              icon="cil-search"
              size="lg"
              class="my-1 mx-2 text-disabled"
            />
          </CInputGroupText>
          <CFormInput
            placeholder="Search..."
            ariaLabel="Search"
            ariaDescribedby="search-addon"
            class="bg-light border-0"
          />
        </CInputGroup>
      </CForm>-->
      <CHeaderNav class="d-none d-sm-flex ms-auto me-3">
        <!--<AppHeaderDropdownNotif />
        <AppHeaderDropdownTasks />
        <AppHeaderDropdownMssgs />-->
      </CHeaderNav>
      <CHeaderNav class="ms-auto ms-sm-0 me-sm-4">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
      <!--<CHeaderToggler class="px-md-0 me-md-3">
        <CIcon
          icon="cil-applications-settings"
          size="lg"
          @click="$store.commit('toggleAside')"
        />
      </CHeaderToggler>-->
    </CContainer>
  </CHeader>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
//import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
//import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
//import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logoNegative } from '@/assets/brand/logo-negative'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    // AppHeaderDropdownMssgs,
    // AppHeaderDropdownNotif,
    // AppHeaderDropdownTasks,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
