export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-chart-pie',
  },
  {
    component: 'CNavTitle',
    name: 'Users',
  },
  {
    component: 'CNavItem',
    name: 'User',
    to: '/users/user',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Contributor',
    to: '/users/contributor',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Franchisee',
    to: '/users/franchisee',
    icon: 'cil-user',
  },
  {
    component: 'CNavTitle',
    name: 'Ads',
  },
  {
    component: 'CNavItem',
    name: 'Images',
    to: '/ads/images',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Text',
    to: '/ads/text',
    icon: 'cil-notes',
  },
  {
    component: 'CNavTitle',
    name: 'Errors',
  },
  {
    component: 'CNavItem',
    name: 'Google',
    to: '/errors/google',
    icon: 'cib-google',
  },
  {
    component: 'CNavItem',
    name: 'Facebook',
    to: '/errors/facebook',
    icon: 'cib-facebook',
  },
  {
    component: 'CNavTitle',
    name: 'Settings',
  },
  {
    component: 'CNavItem',
    name: 'Settings',
    to: '/settings',
    icon: 'cil-settings',
  },
]
