import { createStore } from 'vuex'
import auth from './auth/'
import users from './users/'
import settings from './settings/'
import ads from './ads/'
import errors from './errors/'
import dashboard from './dashboard/'
import data from './data/'
export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    loader: false,
    alerts: [],
    user: null,
  },
  getters: {
    getLoader(state) {
      return state.loader
    },
    getAlerts(state) {
      return state.alerts
    },
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    set(state, [variable, value]) {
      state[variable] = value
    },
    setLoader(state, value) {
      state.loader = !!value
    },
    addAlerts(state, alert) {
      const lastId =
        state.alerts.length !== 0
          ? state.alerts[state.alerts.length - 1].id
          : false
      state.alerts.push({ ...alert, id: lastId ? lastId + 1 : 1 })
    },
    removeAlert(state, id) {
      const index = id ? state.alerts.findIndex((item) => item.id === id) : 0
      state.alerts.splice(index, 1)
    },
  },
  actions: {},
  modules: { auth, users, settings, ads, errors, dashboard, data },
})
