<template>
  <div v-if="vis" class="spiner-wrap">
    <CSpinner color="info" />
    <div class="spiner-bg" />
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  computed: {
    vis() {
      return this.$store.getters.getLoader
    },
  },
}
</script>

<style scoped>
.spiner-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1111;
}

.spiner-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}
</style>
